import React from 'react'
import { IconProps } from '@interface'

const CheckIcon: React.FunctionComponent<IconProps> = (props) => {
  const { isDecorative, size, fill, ...other } = props

  const iconAttributes = { ...other, ...isDecorative && { focusable: false, "aria-hidden": true } }

  return <svg height={size} width={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill={fill} {...iconAttributes}><path d="M9 16.17L5.53 12.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.18 4.18c.39.39 1.02.39 1.41 0L20.29 7.71c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0L9 16.17z"/></svg>
}

CheckIcon.defaultProps = {
  isDecorative: true,
  fill: 'currentColor',
  size: '24',
} as Partial<IconProps>

export default CheckIcon
