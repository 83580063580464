import React from 'react'
import { IconProps } from '@interface'

const InfoIcon: React.FunctionComponent<IconProps> = (props) => {
  const { isDecorative, size, fill, ...other } = props

  const iconAttributes = { ...other, ...isDecorative && { focusable: false, "aria-hidden": true } }

  return <svg height={size} width={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill={fill} {...iconAttributes}><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 15c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1-8h-2V7h2v2z"/></svg>
}

InfoIcon.defaultProps = {
  isDecorative: true,
  fill: 'currentColor',
  size: '24',
} as Partial<IconProps>

export default InfoIcon
