import React from 'react'
import { IconProps } from '@interface'

const FlagIcon: React.FunctionComponent<IconProps> = (props) => {
  const { isDecorative, size, fill, ...other } = props

  const iconAttributes = { ...other, ...isDecorative && { focusable: false, "aria-hidden": true } }

  return <svg height={size} width={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill={fill} {...iconAttributes}><path d="M14.4 6l-.24-1.2c-.09-.46-.5-.8-.98-.8H6c-.55 0-1 .45-1 1v15c0 .55.45 1 1 1s1-.45 1-1v-6h5.6l.24 1.2c.09.47.5.8.98.8H19c.55 0 1-.45 1-1V7c0-.55-.45-1-1-1h-4.6z"/></svg>
}

FlagIcon.defaultProps = {
  isDecorative: true,
  fill: 'currentColor',
  size: '24',
} as Partial<IconProps>

export default FlagIcon
