import React from 'react'
import { IconProps } from '@interface'

const MenuIcon: React.FunctionComponent<IconProps> = (props) => {
  const { isDecorative, size, fill, ...other } = props

  const iconAttributes = { ...other, ...isDecorative && { focusable: false, "aria-hidden": true } }

  return <svg height={size} width={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill={fill} {...iconAttributes}><path d="M4 18h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zm0-5h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zM3 7c0 .55.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1z"/></svg>
}

MenuIcon.defaultProps = {
  isDecorative: true,
  fill: 'currentColor',
  size: '24',
} as Partial<IconProps>

export default MenuIcon
